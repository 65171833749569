// src/store/useCart.js
import { ref } from "vue";

const cart = ref(JSON.parse(localStorage.getItem("cart")) || []);

export function addToCart(product, quantity) {
  const cartItem = { product, quantity };
  cart.value.push(cartItem);
  localStorage.setItem("cart", JSON.stringify(cart.value));
}

export function removeFromCart(index) {
  cart.value.splice(index, 1);
  localStorage.setItem("cart", JSON.stringify(cart.value));
}

export function clearCart() {
  cart.value = [];
  localStorage.removeItem("cart");
}

export function getCart() {
  return JSON.parse(localStorage.getItem("cart")) || [];
}

export function getCartCount() {
  return cart.value.length;
}
