<template>
  <div class="cart-page">
    <h1>Winkelwagen</h1>
    <div v-if="cart.length === 0" class="empty-cart">
      <p>Uw winkelwagen is leeg.</p>
    </div>
    <div v-else class="cart-items">
      <div v-for="(item, index) in cart" :key="index" class="cart-item">
        <img
          v-if="item.product && item.product.image"
          :src="item.product.image"
          :alt="item.product.name"
          class="product-image"
        />
        <div class="item-details">
          <h2>{{ item.product?.name || "Onbekend product" }}</h2>
          <p v-if="item.product?.price">
            Prijs per m²: €{{ item.product.price.toFixed(2) }}
          </p>
          <p>Aantal m²: {{ item.quantity }}</p>
          <p v-if="item.product?.price">
            Totaal: €{{ (item.product.price * item.quantity).toFixed(2) }}
          </p>
        </div>
        <button @click="removeFromCartFunction(index)" class="btn btn-danger">
          <i class="fas fa-trash"></i>
        </button>
      </div>
      <button
        type="button"
        class="btn btn-primary mt-3"
        data-bs-toggle="modal"
        data-bs-target="#quoteModal"
      >
        Offerte aanvragen
      </button>
    </div>

    <!-- Bootstrap Modal -->
    <div
      class="modal fade"
      id="quoteModal"
      tabindex="-1"
      aria-labelledby="quoteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="quoteModalLabel">Offerte Aanvragen</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitQuote">
              <div class="mb-3">
                <label for="name" class="form-label">Naam</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="contact.name"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">E-mail</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="contact.email"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="phone" class="form-label">Telefoonnummer</label>
                <input
                  type="tel"
                  class="form-control"
                  id="phone"
                  v-model="contact.phone"
                  required
                />
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Annuleren
                </button>
                <button type="submit" class="btn btn-primary">Verstuur</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { getCart, removeFromCart, clearCart } from "@/store/useCart"; // Centralized cart composable
import axios from "axios";

const cart = ref(getCart());
const contact = ref({ name: "", email: "", phone: "" });

// Watch for cart changes in localStorage
watchEffect(() => {
  cart.value = getCart();
});

function removeFromCartFunction(index) {
  removeFromCart(index);
  cart.value = getCart();
}

async function submitQuote() {
  if (cart.value.length === 0) return alert("Uw winkelwagen is leeg.");

  const requestData = {
    cart: cart.value,
    contact: contact.value,
  };

  const apiUrl = process.env.VUE_APP_API_URL;
  console.log("emal hier", apiUrl);
  try {
    await axios.post(`${apiUrl}/api/send-cart-quote`, requestData);
    alert("Offerte aanvraag verzonden!");
    clearCart();
    cart.value = [];
    document.querySelector("#quoteModal .btn-close").click(); // Close the modal programmatically
  } catch (error) {
    alert("Er is iets misgegaan bij het verzenden van uw offerte.");
  }
}
</script>

<style scoped>
.cart-page {
  padding: 20px;
}
.cart-items {
  max-width: 600px;
  margin: 0 auto;
}
.cart-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.product-image {
  max-width: 100px;
  border-radius: 5px;
}
.item-details {
  flex-grow: 1;
  margin-left: 20px;
}
</style>
