<template>
  <div class="product-page">
    <h1>Producten Pagina</h1>

    <!-- Search and Sort Section -->
    <form @submit.prevent="fetchFilteredTiles" class="search-sort">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Zoek op productnaam..."
        class="search-input"
      />

      <div class="sort-options">
        <label for="sort-by">Sorteer op:</label>
        <select v-model="sortOrder" id="sort-by" class="sort-select">
          <option value="default">Standaard</option>
          <option value="price-asc">Prijs: Laag naar Hoog</option>
          <option value="price-desc">Prijs: Hoog naar Laag</option>
          <option value="name-asc">Naam: A-Z</option>
          <option value="name-desc">Naam: Z-A</option>
        </select>
      </div>

      <button type="submit" class="btn btn-primary">Zoek</button>
    </form>

    <!-- Products Grid -->
    <div class="product-grid">
      <div v-for="product in tiles" :key="product.id" class="product-card">
        <img
          :src="getImageUrl(product.id)"
          :alt="product.color || 'Tile image'"
          class="product-image"
        />
        <h2>{{ product.color || "Geen kleur opgegeven" }}</h2>
        <p class="price">
          €{{
            product.price ? product.price.toFixed(2) : "Prijs niet beschikbaar"
          }}
        </p>
        <p v-if="product.discount">Korting: {{ product.discount }}%</p>

        <p class="dimensions">
          Afmetingen: {{ product.length }}x{{ product.width }}mm
        </p>

        <button @click="viewProductDetails(product)" class="btn btn-primary">
          Bekijk product
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const tiles = ref([]);
const searchQuery = ref("");
const sortOrder = ref("default");
const apiUrl = process.env.VUE_APP_API_URL;

// Fetch filtered tiles from the backend
async function fetchFilteredTiles() {
  try {
    const params = {
      name: searchQuery.value || undefined,
      sortBy: getSortField(),
      limit: 10, // Default limit
    };

    console.log("emal hier", apiUrl);

    const response = await axios.get(`${apiUrl}/api/tiles/filter`, { params });
    tiles.value = response.data;
  } catch (error) {
    console.error("Error fetching tiles:", error);
  }
}

// Fetch all tiles on component mount
onMounted(() => {
  fetchFilteredTiles();
});

// Determine the sort field and direction
function getSortField() {
  switch (sortOrder.value) {
    case "price-asc":
      return "price";
    case "price-desc":
      return "price,desc";
    case "name-asc":
      return "color";
    case "name-desc":
      return "color,desc";
    default:
      return "id";
  }
}

// Get image URL for each tile
function getImageUrl(tileId) {
  return `${apiUrl}/api/tiles/${tileId}/image`; // Update this to match your backend endpoint
}

// Navigate to product details page
function viewProductDetails(product) {
  router.push({
    path: `/product`,
    query: {
      id: product.id,
      name: product.color,
      price: product.price,
      image: getImageUrl(product.id),
    },
  });
}
</script>

<style lang="scss" scoped>
.product-page {
  padding: 20px;
  text-align: center;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  width: 300px;
  text-align: center;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.1);
}

.product-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.price {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

.dimensions {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 10px;
}

.btn {
  background-color: #42b983;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #369f73;
}
</style>
