<template>
  <div class="product-page">
    <h1>{{ title }}</h1>

    <!-- Search and Sort Section -->
    <div class="search-sort">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Zoek op productnaam..."
        class="search-input"
      />

      <div class="sort-options">
        <label for="sort-by">Sorteer op:</label>
        <select v-model="sortOrder" id="sort-by" class="sort-select">
          <option value="default">Standaard</option>
          <option value="price-asc">Prijs: Laag naar Hoog</option>
          <option value="price-desc">Prijs: Hoog naar Laag</option>
          <option value="name-asc">Naam: A-Z</option>
          <option value="name-desc">Naam: Z-A</option>
        </select>
      </div>
    </div>

    <div class="product-grid">
      <div
        v-for="product in sortedProducts"
        :key="product.id"
        class="product-card"
      >
        <img :src="product.image" :alt="product.name" class="product-image" />
        <h2>{{ product.name }}</h2>
        <p class="price">€{{ product.price.toFixed(2) }} per m²</p>

        <button @click="requestQuote(product)" class="btn btn-primary">
          Bekijk product
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { defineProps, ref, computed } from "vue";

const router = useRouter();

// Props om producten en de titel van de pagina door te geven
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  products: {
    type: Array,
    required: true,
  },
});

// Zoek- en sorteergegevens
const searchQuery = ref("");
const sortOrder = ref("default");

const sortedProducts = computed(() => {
  let sortedArray = [...props.products].filter((product) => {
    return product.name.toLowerCase().includes(searchQuery.value.toLowerCase());
  });

  if (sortOrder.value === "price-asc") {
    sortedArray.sort((a, b) => a.price - b.price);
  } else if (sortOrder.value === "price-desc") {
    sortedArray.sort((a, b) => b.price - a.price);
  } else if (sortOrder.value === "name-asc") {
    sortedArray.sort((a, b) => a.name.localeCompare(b.name));
  } else if (sortOrder.value === "name-desc") {
    sortedArray.sort((a, b) => b.name.localeCompare(a.name));
  }

  return sortedArray;
});

function requestQuote(product) {
  // Leid de gebruiker naar de offertepagina met de geselecteerde productinformatie
  router.push({
    path: "/offerte",
    query: {
      id: product.id,
      name: product.name,
      price: product.price,
      image: product.image,
    },
  });
}
</script>

<style lang="scss" scoped>
.product-page {
  padding: 20px;
  text-align: center;
}

.search-sort {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.search-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 200px;
}

.sort-options {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sort-select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.1);
}

.product-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.product-card h2 {
  font-size: 1.5rem;
  margin: 10px 0;
  flex-grow: 1;
}

.price {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 15px;
}

.btn {
  background-color: #42b983;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.btn:hover {
  background-color: #369f73;
}
</style>
